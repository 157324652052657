<template>
    <nav id="navbarys" class="navbar navbar-expand-lg #navbar-light bg-semi-light #fixed-top">
        <a class="navbar-brand" href="#">
            <img src="@/assets/logo1.png" alt="logo" class="ml-3" style="width: 50px;">
            <img src="@/assets/Tracé.png" alt="logo" class="ml-3">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown" v-if="this.$store.getters.user.is_admin && !this.$store.getters.transporteur">
                    <message-component></message-component>
                </li>
                <li class="nav-item dropdown" v-if="this.$store.getters.user.is_admin && !this.$store.getters.transporteur">
                    <notification-component></notification-component>
                </li>
                <li class="nav-item dropdown">
                    <button class="btn btn-light dropdown" data-toggle="dropdown" aria-expanded="false" type="button">
                        <i class="fa-solid fa-chevron-down text-sm"></i>
                        <span class="mx-1">
                            {{ this.$store.state.user.name }} 
                        </span>
                        <img :src="this.$store.state.user.photo"  class="rounded-circle" width="25px">
                    </button>
                    <div class="dropdown-menu">
                        <a href="#mon-profil" class="dropdown-item" @click.prevent="openShowModal()">
                            <i class="fas fa-user"></i> Mon profil
                        </a>
                        <a href="#se-deconnecter" class="dropdown-item text-danger" @click.prevent="logout()">
                            <i class="fas fa-arrow-right"></i> Se déconnecter
                        </a>
                    </div>
                </li>
                <li class="nav-item">
                    <button class="btn btn-light mx-1" @click="openFullscreen()">
                        <i class="fa-solid fa-display"></i>
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import $ from "jquery";
    import MyProfile from '@/auth/MyProfile'
    import NotificationComponent from "@/components/NotificationComponent.vue";
    import MessageComponent from "@/components/MessageComponent.vue";
    
    export default {
        components: { NotificationComponent, MessageComponent },
        name: 'NavBar',
        methods: {
            logout () {
                this.isLoading = true
                this.$store.commit('clearUserData')
                this.axios.post('/api/logout').then(response => {
                    if(response.data.success === true){
                        window.location.reload()
                        this.$toast.add({
                            severity: 'success',
                            detail: response.data.message,
                            life: 3000
                        });
                    }
                    else {
                        response.data.errors.forEach(element => {
                            this.$toast.error(element, {
                                duration: '8000',
                                position: 'top-left'
                            })
                        });
                    }
                    this.isLoading = false
                }).catch(() => {
                    this.isLoading = false
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Probleme de connexion',
                        detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                        life: 5000
                    });
                })

                window.location.reload()
            },
            openProfile (){
                $('#btnmon-profil').click()
            },
            refreshGlobalKey () {
                this.$store.commit('setIsLoading', true)
                this.$store.commit('setGlobalKey')
                this.logout()
                setTimeout(() => {
                    this.$store.commit('setIsLoading', false)
                    window.cache.delete()
                    window.location.reload()
                }, 10000)
            },
            openShowModal() {
                this.$dialog.open(MyProfile, {
                props: {
                    header: "Mon profil",
                    style: {
                    width: '70vw'
                    },
                    modal: true
                },
                });
            },
            openFullscreen() {
                var elem = document.getElementById("yamsmanager");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
            }
           
        }
    }
</script>

<style scoped>
    .bg-semi-light{
        background-color: rgba(255, 255, 255, 0.9)    }
</style>