
import { defineComponent } from 'vue';
import RowsCounter from '@/components/RowsCounter.vue';
import store from '@/store';

export default defineComponent({
  name: 'HomeView',
  components: { RowsCounter },
  methods: {
    getPermission(permission: string) {
      return store.getters.permissions.includes(permission)
    },
    
  }
})
