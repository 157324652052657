
import { defineComponent } from 'vue';
import TempNavBar from '@/layouts/NavBar.vue'
import TempSideBar from '@/layouts/SideBar.vue'
import TempFooter from '@/layouts/FooterBar.vue'
import store from '../src/store'

export default defineComponent({
  name: 'App',
  components: { TempNavBar, TempSideBar, TempFooter },
  data() {
    return {
      isLoading: false,
      Notifications_a_lire: {}
    }
  },
  beforeMount() {
    Notification.requestPermission().then((result) => {
      console.log(result);
    });
  },
  created() {
    store.commit('setIsLoading', true)
    this.axios.post('/api/premiere-connexion').then(() => {
      this.axios.get('/sanctum/csrf-cookie')
      store.commit('setIsLoading', false)
    }).catch(() => {
      window.location.reload()
      this.$toast.add({
        severity: 'error',
        summary: 'Probleme de connexion',
        detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
        life: 5000
      });
    });
  },
  mounted(){
    if (store.getters.authenticated) {
      this.getTransporteurAccount()
    }
    
  },
  methods: {
    getTransporteurAccount(){
      this.axios.get('/api/proprietaires/user/'+store.getters.user.id).then((response) => {
        const comptes = response.data
        if (comptes) {
          store.commit('setTransporteur', comptes)
        }
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      });
    },
    // getNotifications() {
    //   this.axios.get('/api/notifications?read_at=null').then((response) => {
    //       this.Notifications_a_lire = response.data
    //       response.data.forEach(element => {
    //           if (element.read_at == null) {
    //               const notif = new Notification("App YAM'S Logistics", { 
    //                   body: element.data.user_name+' '+element.data.libelle,
    //                   icon: element.data.user_photo 
    //               });
    //               this.Notifications_a_lire.push({
    //                   id: element.id,
    //                   notification: notif
    //               })
    //           }
    //       });
    //       // this.refreshCounter()
    //       this.isLoading = false
    //   }).catch(() => {
    //       this.isLoading = false
    //   })
    // }
  },
})
