<template>
    <div>
        <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
        <div class="d-flex mb-3">
            <button type="button" class="btn btn-sm btn-info mr-2" @click="openEditModal()">
                <i class="fas fa-edit"></i> Modifier
            </button>

            <button class="btn btn-primary btn-sm mr-2" @click="exportPDF()">
                <i class="fa-solid fa-download"></i> Télécharger
            </button>

            <button type="button" class="btn btn-sm btn-danger mr-2" @click.prevent="deleteLine()">
                <i class="fas fa-trash-alt"></i> Supprimer
            </button>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>Date d'opération <span class="text-danger">*</span></label>
                <input type="date" class="form-control" v-model="datas.date_operation" disabled />
            </div>
            <div class="form-group col-md-6">
                <label>Bénéficiaire <span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="datas.beneficiaire" disabled />
            </div>
            <div class="form-group col-md-6">
                <label>Décaisseur <span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="datas.decaisseur" disabled />
            </div>
            <div class="form-group col-md-6">
                <label>Montant <span class="text-danger">*</span></label>
                <input type="number" class="form-control" v-model="datas.montant" disabled />
            </div>
            <div class="form-group col-md-6">
                <label>Libelle <span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="datas.libelle" disabled />
            </div>
        </div>
        <div class="form-group mb-4">
            <label> Motif </label>
            <div v-html="datas.motif"></div>
        </div>
    </div>

    <!-- pdf fiche caisse -->
    <div class="container-fluid" hidden>
        <div :id="'piece-de-caisse-' + this.datas.id" class=" w-100">
            <div>
                <div class="d-flex justify-content-center text-center align-items-center mb-1">
                    <div class="col-3">
                        <img src="@/assets/logo.png"  style="width: 150px">
                    </div>
                    <div class="col-6">
                        <h1 class="text-primary">REÇU DE CAISSE</h1>
                    </div>
                    <div class="col-3">
                        <img src="@/assets/yams-qrcode-links.png"  style="width: 100px">
                    </div>
                </div>
                <div class="mb-2">
                    <div >
                        <div class="row">
                            <div class="col-8">
                                <strong>À Mr/Mme/Mlle : </strong> {{ this.datas.beneficiaire }}<br>
                                <strong>Montant: </strong> {{  new Intl.NumberFormat('de-DE', {
                                    style: 'currency', currency: 'XAF'
                                    }).format(this.datas.montant)}} <br>
                                
                                <strong>Observation: </strong> {{ this.datas.libelle }}
                            </div>
                            <div class="col-4">
                                <strong>Numero : </strong>  PC{{ this.datas.id }}<br>
                                <strong>Date: </strong> {{ dateFormat(this.datas.date_operation) }} <br>
                            </div>
                        </div>
                
                        <div class="row border border-dark mb-3">
                            <div class="col-md-6 border-right  border-secondary" >
                                <h6>Décaisseur:  </h6>
                                {{ this.datas.decaisseur }}
                            </div>
                            <div class="col-md-6  pb-4 border-right  border-secondary" >
                                <h6>Motif:  </h6>
                                <div v-html="this.datas.motif"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="d-flex justify-content-between">
                    <div class="">
                        <h6>Signature du DG</h6>
                    </div>
                    <div class="">
                        <h6>Signature du comptable</h6>
                    </div>
                    <div class="">
                        <h6>Signature du bénéficiaire</h6>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import $ from "jquery";
import moment from 'moment'
import { defineComponent, defineAsyncComponent } from 'vue';
const EditCaisse = defineAsyncComponent(() => import('./EditCaisse.vue'));
const ExportCaisse = defineAsyncComponent(() => import('./ExportCaisse.vue'));


export default defineComponent({
    name: 'CreateCaisse',
    inject: ['dialogRef'],
    component : { ExportCaisse },
    data() {
        return {
            isLoading: false,
            table: 'piece_de_caisses',
            datas: this.dialogRef.data
        }
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        dateFormat(varDate) {
            if (varDate != '') {
                const newDate = new Date(varDate)
                if (newDate) return moment(newDate).format('DD/MM/YYYY')
            }
        },
        openEditModal() {
            this.dialogRef.close()
            this.$dialog.open(EditCaisse, {
                props: {
                    header: "Piece de caisse ID" + this.datas.id,
                    style: {
                        width: '30vw'
                    },
                    modal: true
                },
                data: this.datas
            });
        },
        deleteLine() {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }
            this.isLoading = true
            this.axios.post('/api/piece_de_caisses/' + this.datas.id + '/destroy').then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    this.dialogRef.close()
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Supprimé',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 20000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        exportPDF() {
            html2pdf().set({
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a5', orientation: 'landscape' },
                margin: [0.5, 0.5],
                filename: "Piece de caisse n°" + this.datas.id,
                html2canvas: { scale: 2 }
            }).from(document.getElementById("piece-de-caisse-" + this.datas.id))
                .toPdf()
                .get('pdf')
                .save();
        },
    }

})
</script>
