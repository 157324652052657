<template>
  <div class="header-contentys pt-3">
    <div class=" d-flex align-items-center">
      <div class="mr-5">
        <h4 class="text-primary font-weight-bold">Interventions ({{ lines }})</h4>
      </div>
      <div class="mr-4">
        <NavParcAuto></NavParcAuto>
      </div>
      <div class="btn-group page-nav mr-4" role="group" aria-label="Basic example">
        <a href="" class="btn " @click.prevent="changeAffichage('list')" :class="{ 'active': affichage === 'list' }">
          <i class="fa-solid fa-bars"></i>
        </a>
        <a href="" class="btn " @click.prevent="changeAffichage('cards')" :class="{ 'active': affichage === 'cards' }">
          <i class="fa-solid fa-border-all"></i>
        </a>
      </div>
      <div class="px-1 d-flex">
        <button type="button" class="btn-yams btn-active mr-2" @click="openCreateModal()"
          v-if="getPermission('creer-interventions') === true">
          Ajouter une intervention <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <hr>
  <div class="body-contentys ">
    <p v-if="!this.$store.getters.transporteur">
      <a class="btn btn-sm btn-secondary" data-toggle="collapse" href="#collapseExample" role="button"
        aria-expanded="false" aria-controls="collapseExample">
        <i class="fa-solid fa-bars mr-1"></i> Afficher les ressources
      </a>
    </p>
    <div class="collapse" id="collapseExample">
      <div class="card p-2 card-body mb-1">
        <div class="d-flex">
          <div class="mr-2" >
            <FicheIntervention></FicheIntervention>
          </div>
          <div class="mr-2">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openGarageModal()"
              v-if="getPermission('voir-garages') === true">
              Garages
            </button>
          </div>
          <div class="mr-2">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openServiceModal()"
              v-if="getPermission('voir-services') === true">
              Services
            </button>
          </div>
          <div class="mr-2">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openPieceModal()"
              v-if="getPermission('voir-pieces') === true">
              Pièces
            </button>
          </div>
          <div class="mr-2">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openFournisseurModal()"
              v-if="getPermission('voir-fournisseurs') === true">
              Fournisseurs
            </button>
          </div>
          <div class="mr-2">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openTypeModal()"
              v-if="getPermission('voir-types_pieces') === true">
              Types pièces
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex" v-if="!this.$store.getters.transporteur">
        <div class="col-md-2 mr-2" :hidden="filterOpen" >
          <filter-intervention :url="baseURL" v-on:set-filtered-url="updateURL"></filter-intervention>
        </div>
        <div class="w-100">
          <DataTableBackend :key="key" :url="url" :idParams="this.$route.params.id" backendFilter :columns="columns" :table="table"
            :affichage="affichage" selection inCard v-on:set-total-records="updateLines">
            <template #action_group_btns=slotProps>
              <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
                <i class="fas fa-trash-alt"></i> Supprimer
                <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </template>
            <template #action_btns=slotProps>
              <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                <i class="fa-solid fa-eye"></i>
              </button>
            </template>
          </DataTableBackend>
        </div>
    </div>
    <div class="d-flex" v-else>
      <DataTableBackend :noFilter="true" :key="key" :url="url" :idParams="this.$route.params.id" backendFilter :columns="columns" :table="table"
            :affichage="affichage"  inCard v-on:set-total-records="updateLines">
            <template #action_btns=slotProps>
              <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                <i class="fa-solid fa-eye"></i>
              </button>
            </template>
          </DataTableBackend>
    </div>
    
  </div>
</template>
    
<script >
import $ from "jquery"
import { defineComponent, defineAsyncComponent } from 'vue';
import NavParcAuto from '../NavParcAuto.vue';
import FicheIntervention from './FicheIntervention.vue';
import FilterIntervention from './FilterIntervention.vue';
const CreateInterventionVue = defineAsyncComponent(() => import('./CreateIntervention.vue'));
const ShowInterventionVue = defineAsyncComponent(() => import('./ShowIntervention.vue'));
const IndexGarage = defineAsyncComponent(() => import('./garages/IndexGarage.vue'));
const IndexPiece = defineAsyncComponent(() => import('@/views/parc-auto/camions/articles/IndexArticle.vue'));
const IndexService = defineAsyncComponent(() => import('./services/IndexService.vue'));
const FournisseuIndex = defineAsyncComponent(() => import('./fournisseurs/FournisseurIndex.vue'));
const TypeIndex = defineAsyncComponent(() => import('@/views/parc-auto/camions/articles/types_articles/IndexTypeArticle.vue'));

export default defineComponent({
  name: 'IndexIntervention',
  components: { NavParcAuto, FicheIntervention, FilterIntervention },
  data() {
    return {
      lines: 0,
      key: 1,
      filterOpen: false,
      affichage: "list",
      baseURL: '/api/interventions',
      url: '/api/interventions',
      table: "interventions",
      columns: [
        {
          key: 'camion.plaque',
          label: 'Immatriculation',
          exportable: true
        },
        {
          key: 'date_debut',
          label: 'Debut',
          format: 'date',
          exportable: true
        },
        {
          key: 'date_fin',
          label: 'Fin',
          format: 'date',
          exportable: true
        },
        {
          key: 'type',
          label: 'Type',
          exportable: true
        },
        {
          key: 'montant',
          label: 'Montant',
          format: 'money',
          exportable: true
        },
        {
          key: 'garage.nom',
          label: 'Garage',
          exportable: true
        },
        {
          key: 'statut',
          label: 'Statut',
          format: 'state'
        }
      ]
    };
  },
  created(){
    if (this.$store.getters.transporteur) {
      this.baseURL = '/api/interventions/proprietaire/'+this.$store.getters.transporteur.id
      this.url = '/api/interventions/proprietaire/'+this.$store.getters.transporteur.id
    }
  },
  methods: {
    getPermission(permission) {
      return this.$store.getters.permissions.includes(permission)
    },
    changeAffichage(type) {
      this.affichage = type;
      this.key++
    },
    openCreateModal() {
      this.$dialog.open(CreateInterventionVue, {
        props: {
          header: "Nouvelle intervention",
          style: {
            width: '50vw'
          },
          modal: true
        },
      });
    },
    openShowModal(objData) {
      this.$dialog.open(ShowInterventionVue, {
        props: {
          header: "Intervention ID" + objData.id,
          style: {
            width: '50vw'
          },
          modal: true
        },
        data: objData
      });
    },
    openGarageModal() {
      this.$dialog.open(IndexGarage, {
        props: {
          header: "Gestion de Garages",
          style: {
            width: '50vw'
          },
          modal: true
        }
      });
    },
    openPieceModal() {
      this.$dialog.open(IndexPiece, {
        props: {
          header: "Gestion des pièces",
          style: {
            width: '50vw'
          },
          modal: true
        }
      });
    },
    openServiceModal() {
      this.$dialog.open(IndexService, {
        props: {
          header: "Gestion de Services",
          style: {
            width: '50vw'
          },
          modal: true
        }
      });
    },
    openFournisseurModal() {
      this.$dialog.open(FournisseuIndex, {
        props: {
          header: "Gestion de Fournisseurs",
          style: {
            width: '60vw'
          },
          modal: true
        }
      });
    },

    openTypeModal() {
      this.$dialog.open(TypeIndex, {
        props: {
          header: "Gestion des Types d'articles",
          style: {
            width: '40vw'
          },
          modal: true
        }
      });
    },

    deleteGroup(selection) {
      if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
        return
      }

      this.isLoading = true
      let selectedLines = []
      for (let index = 0; index < selection.length; index++) {
        const element = selection[index];
        selectedLines.push(element.id)
      }

      this.axios.post('/api/interventions/destroy-group', { selected_lines: selectedLines }).then((response) => {
        this.isLoading = false
        if (response.data.success === true) {
          $('#refresh' + this.table).click()
          for (let index = 0; index < response.data.message.length; index++) {
            const element = response.data.message[index];
            this.$toast.add({
              severity: element.severity,
              detail: element.value,
              life: parseInt(5000 * (index + 1))
            });
          }
        }
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    updateLines(nb_lines) {
      this.lines = nb_lines
    },
    updateURL(url) {
            this.url = url
            this.key++
        },
    useFilter() {
        if (this.filterOpen == true) {
            this.filterOpen = false
        } else this.filterOpen = true
    },
  }
});
</script>
      