<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h4 class="text-primary font-weight-bold">Courses ({{ lines }})</h4>
        </div>
        <div class="mr-4">
            <NavOperations></NavOperations>
        </div>
        <div class="btn-group page-nav mr-4" role="group" aria-label="Basic example">
            <a href="" class="btn " @click.prevent="changeAffichage('list')"
                :class="{ 'active': affichage === 'list' }">
                <i class="fa-solid fa-bars"></i>
            </a>
            <a href="" class="btn " @click.prevent="changeAffichage('cards')"
                :class="{ 'active': affichage === 'cards' }">
                <i class="fa-solid fa-border-all"></i>
            </a>
        </div>
        <div>
            <button type="button" class="btn-yams btn-active" @click="openCreateModal()"
                v-if="getPermission('creer-courses') === true">
                Ajouter une course <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="body-contentys">
        <div class="d-flex mb-2" v-if="!this.$store.getters.transporteur">
            <div class="mr-2">
                <button type="button" class=" btn-sm btn-secondary btn" @click.prevent="useFilter()">
                    <i class="fa-solid fa-filter"></i>
                </button>
            </div>
            <a class="btn btn-sm btn-secondary" data-toggle="collapse" href="#collapseExample" role="button"
                aria-expanded="false" aria-controls="collapseExample">
                <i class="fa-solid fa-bars mr-1"></i> Afficher les ressources
            </a>
        </div>
        <div class="collapse" id="collapseExample">
            <div class="card p-2 card-body mb-1 d-flex">

                <div class="mr-2">
                    <button type="button" class="btn btn-sm btn-outline-dark" @click="openTrajetModal()"
                        v-if="getPermission('voir-trajets') === true">
                        Trajets
                    </button>
                </div>

            </div>
        </div>
        <div class="d-flex" v-if="!this.$store.getters.transporteur">
            <div class="col-md-2 mr-2" :hidden="filterOpen">
                <filter-course :url="baseURL" v-on:set-filtered-url="updateURL"></filter-course>
            </div>
            <div class="w-100">
                <DataTableBackend :key="key" :url="url" :columns="columns" :affichage="affichage"
                    :idParams="this.$route.params.id" :table="table" selection inCard
                    v-on:set-total-records="updateLines" backendFilter>
                    <template #action_group_btns=slotProps>
                        <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
                            <i class="fas fa-trash-alt"></i> Supprimer
                            <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </button>
                    </template>
                    <template #action_btns=slotProps>
                        <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                            <i class="fa-solid fa-eye"></i>
                        </button>
                    </template>
                </DataTableBackend>
            </div>

        </div>
        <div v-else>
            <DataTableBackend :noFilter="true" :key="key" :url="url" :columns="columns" :affichage="affichage"
                    :idParams="this.$route.params.id" :table="table" inCard
                    v-on:set-total-records="updateLines" >
                    <template #action_btns=slotProps>
                        <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                            <i class="fa-solid fa-eye"></i>
                        </button>
                    </template>
                </DataTableBackend>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import { defineComponent, defineAsyncComponent } from 'vue';
import NavOperations from '../NavOperations.vue';
import FilterCourse from './FilterCourse.vue';
const CreateCourse = defineAsyncComponent(() => import('./CreateCourse.vue'));
const ShowCourseVue = defineAsyncComponent(() => import('./ShowCourse.vue'));
const IndexTrajet = defineAsyncComponent(() => import('./trajets/IndexTrajet.vue'));
export default defineComponent({
    name: 'IndexCourse',
    components: { NavOperations, FilterCourse },
    data() {
        return {
            lines: 0,
            key: 1,
            filterOpen: false,
            baseURL: '/api/courses',
            url: '/api/courses',
            affichage: "list",
            table: 'courses',
            columns: [
                {
                    key: 'code',
                    label: 'Code',
                    exportable: true
                },
                {
                    key: 'camion.plaque',
                    label: 'Camion',
                    exportable: true
                },
                {
                    key: 'client.name',
                    label: 'Client',
                    exportable: true
                },
                {
                    key: 'type_course',
                    label: 'Type',
                    exportable: true
                },
                {
                    key: 'trajet.libelle',
                    class: 'bg-secondary p-1 rounded text-white text-center',
                    stringWithKeys: [
                        {
                            var: 'adresse_chargement',
                            text: '('
                        },
                        {
                            var: 'date_chargement',
                            text: ') -',
                            format: 'date'
                        },
                        {
                            var: 'adresse_dechargement',
                            text: '('
                        },
                        {
                            var: 'date_dechargement',
                            text: ')',
                            format: 'date'
                        }
                    ],
                    label: 'Trajet',
                    exportable: true
                },

                {
                    key: 'montant',
                    label: 'Montant',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'Reglement',
                    format: 'state'
                },
                {
                    key: 'etat',
                    label: 'Etat',
                    format: 'state'
                },
            ],
        };
    },
    created() {
        if (this.$store.getters.transporteur) {
            this.baseURL = '/api/courses?proprietaire_id=' + this.$store.getters.transporteur.id
            this.url = '/api/courses?proprietaire_id=' + this.$store.getters.transporteur.id
        }
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        changeAffichage(type) {
            this.affichage = type;
            this.key++
        },
        openShowModal(objData) {
            this.$dialog.open(ShowCourseVue, {
                props: {
                    header: "Course ID" + objData.id,
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
                data: objData
            });
        },
        openCreateModal() {
            this.$dialog.open(CreateCourse, {
                props: {
                    header: "Nouvelle course",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openTrajetModal() {
            this.$dialog.open(IndexTrajet, {
                props: {
                    header: "Gestion des trajets",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
            });
        },
        deleteGroup(selection) {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }

            this.isLoading = true
            let selectedLines = []
            for (let index = 0; index < selection.length; index++) {
                const element = selection[index];
                selectedLines.push(element.id)
            }

            this.axios.post('/api/courses/destroy-group', { selected_lines: selectedLines }).then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    for (let index = 0; index < response.data.message.length; index++) {
                        const element = response.data.message[index];
                        this.$toast.add({
                            severity: element.severity,
                            detail: element.value,
                            life: parseInt(5000 * (index + 1))
                        });
                    }
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        updateURL(url) {
            this.url = url
            this.key++
        },
        useFilter() {
            if (this.filterOpen == true) {
                this.filterOpen = false
            } else this.filterOpen = true
        },
    }
});
</script>