<template>
  <div class="header-contentys pt-3 d-flex align-items-center">
    <div class="mr-5">
      <h4 class="text-primary font-weight-bold">Camions ({{ lines }})</h4>
    </div>
    <div class="mr-4">
      <NavParcAuto></NavParcAuto>
    </div>
    <div class="mr-4">
      <div class="btn-group page-nav " role="group" aria-label="Basic example">
        <a href="" class="btn " @click.prevent="changeAffichage('list')" :class="{ 'active': affichage === 'list' }">
          <i class="fa-solid fa-bars"></i>
        </a>
        <a href="" class="btn " @click.prevent="changeAffichage('cards')" :class="{ 'active': affichage === 'cards' }">
          <i class="fa-solid fa-border-all"></i>
        </a>
      </div>
    </div>
    <div class="px-1 d-flex" v-if="getPermission('creer-camions')">
      <button class="btn-yams btn-active mr-2" @click="openCreateModal()">
        Ajouter un camion <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <!-- <router-link to="/parc-auto/camions" class="btn btn-danger" v-if="url !== '/api/camions'" @click="disableFilter()">
      <i class="fa-solid fa-close"></i> Retirer le filtre
    </router-link> -->
  </div>
  <hr>
  <div class="body-contentys">
    <p v-if="!this.$store.getters.transporteur">
      <a class="btn btn-sm btn-secondary" data-toggle="collapse" href="#collapseExample" role="button"
        aria-expanded="false" aria-controls="collapseExample">
        <i class="fa-solid fa-bars mr-1"></i> Afficher les ressources
      </a>
    </p>
    <div class="collapse" id="collapseExample">
      <div class="card p-2 card-body mb-1">
        <div class="d-flex">
          <div class="mr-2" v-if="getPermission('voir-marques')">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openMarqueModal()">
              Marques
            </button>
          </div>
          <div class="mr-2" v-if="getPermission('voir-modeles')">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openModeleModal()">
              Modeles
            </button>
          </div>
          <div class="mr-2" v-if="getPermission('voir-articles')">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openArticleModal()">
              Articles
            </button>
          </div>
          <div class="mr-2" v-if="getPermission('voir-types_camions')">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openTypeCamionModal()">
              Type de camion
            </button>
          </div>
          <div class="mr-2" v-if="getPermission('voir-types_documents')">
            <button type="button" class="btn btn-outline-dark btn-sm" @click="openTypeDocumentModal()">
              Type de document
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!this.$store.getters.transporteur">
      <DataTableBackend :key="key" :url="url" :columns="columns" :table="table" :affichage="affichage"
        :idParams="this.$route.params.id" inCard selection backendFilter v-on:set-total-records="updateLines">
        <template #action_group_btns=slotProps>
          <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
            <i class="fas fa-trash-alt"></i> Supprimer
            <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
          </button>
        </template>
        <template #action_btns=slotProps>
          <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
            <i class="fa-solid fa-eye"></i>
          </button>
        </template>
      </DataTableBackend>
    </div>
    <div v-else>
      <DataTableBackend :noFilter="true" :key="key" :url="url" :columns="columns" :table="table" :affichage="affichage"
        :idParams="this.$route.params.id" inCard v-on:set-total-records="updateLines">
        <template #action_btns=slotProps>
          <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
            <i class="fa-solid fa-eye"></i>
          </button>
        </template>
      </DataTableBackend>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import { defineComponent, defineAsyncComponent } from 'vue';
import NavParcAuto from '../NavParcAuto.vue';
const ShowCamionVue = defineAsyncComponent(() => import('./ShowCamion.vue'));
const CreateCamion = defineAsyncComponent(() => import('./CreateCamion.vue'));
const IndexMarqueVue = defineAsyncComponent(() => import('./marques/IndexMarque.vue'));
const IndexModeleVue = defineAsyncComponent(() => import('./modeles/IndexModele.vue'));
const IndexPieceVue = defineAsyncComponent(() => import('./articles/IndexArticle.vue'));
const TypeCamionIndex = defineAsyncComponent(() => import('./types_camions/IndexTypeCamion.vue'));
const TypeDocumentIndex = defineAsyncComponent(() => import('./types_documents_camions/TypeIndex.vue'));

export default defineComponent({
  name: "IndexCamion",
  components: { NavParcAuto },
  data() {
    return {
      key: 1,
      lines: 0,
      url: '/api/camions',
      affichage: "list",
      table: "camions",
      isLoading: false,
      columns: [
        {
          key: 'plaque',
          label: 'Immatriculation',
          exportable: true,
          selectable: true
        },
        {
          key: 'modele.marque.libelle',
          label: 'Marque',
          exportable: true
        },
        {
          key: 'modele.libelle',
          label: 'Modele',
          exportable: true
        },
        {
          key: 'types_camion.libelle',
          label: 'Tonnage',
          exportable: true
        },
        {
          key: 'proprietaire.nom',
          label: 'Proprietaire',
          exportable: true
        },
        {
          key: 'statut',
          label: 'Statut',
          format: 'state',
          selectable: true
        }
      ]
    };
  },
  created() {
    if (this.$store.getters.transporteur) {
      this.url = '/api/camions?proprietaire_id=' + this.$store.getters.transporteur.id
    }
  },
  // mounted() {
  //   if (this.$route.href === '/parc-auto/camions/partenaire') {
  //     this.url = '/api/camions?proprietaire=partenaire'
  //     this.key++
  //   }
  //   else if (this.$route.href === '/parc-auto/camions/yams') {
  //     this.url = '/api/camions?proprietaire=yams'
  //     this.key++
  //   }
  // },
  methods: {
    getPermission(permission) {
      return this.$store.getters.permissions.includes(permission)
    },
    changeAffichage(type) {
      this.affichage = type;
      this.key++
    },
    openCreateModal() {
      this.$dialog.open(CreateCamion, {
        props: {
          header: "Nouveau camion",
          style: {
            width: "50vw"
          },
          modal: true
        }
      });
    },
    openShowModal(objData) {
      this.$dialog.open(ShowCamionVue, {
        props: {
          header: "Camion ID" + objData.id,
          style: {
            width: "50vw"
          },
          modal: true
        },
        data: objData
      });
    },
    openMarqueModal() {
      this.$dialog.open(IndexMarqueVue, {
        props: {
          header: "Gestion des marques",
          style: {
            width: '50vw'
          },
          modal: true
        }
      });
    },
    openModeleModal() {
      this.$dialog.open(IndexModeleVue, {
        props: {
          header: "Gestion des modeles",
          style: {
            width: '50vw'
          },
          modal: true
        }
      });
    },
    openArticleModal() {
      this.$dialog.open(IndexPieceVue, {
        props: {
          header: "Gestion des articles",
          style: {
            width: '80vw'
          },
          modal: true
        }
      });
    },
    openTypeCamionModal() {
      this.$dialog.open(TypeCamionIndex, {
        props: {
          header: "Gestion des types de camions",
          style: {
            width: '40vw'
          },
          modal: true
        }
      });
    },
    openTypeDocumentModal() {
      this.$dialog.open(TypeDocumentIndex, {
        props: {
          header: "Gestion des types de document",
          style: {
            width: '40vw'
          },
          modal: true
        }
      });
    },
    deleteGroup(selection) {
      if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
        return
      }

      this.isLoading = true
      let selectedLines = []
      for (let index = 0; index < selection.length; index++) {
        const element = selection[index];
        selectedLines.push(element.id)
      }

      this.axios.post('/api/camions/destroy-group', { selected_lines: selectedLines }).then((response) => {
        this.isLoading = false
        if (response.data.success === true) {
          $('#refresh' + this.table).click()
          for (let index = 0; index < response.data.message.length; index++) {
            const element = response.data.message[index];
            this.$toast.add({
              severity: element.severity,
              detail: element.value,
              life: parseInt(5000 * (index + 1))
            });
          }
        }
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    updateLines(nb_lines) {
      this.lines = nb_lines
    }
  },
});
</script>