<template>
  <div class="sidebar-container">
    <div class="sidebar">
      <nav class="navys flex-column justify-content-center">
        <router-link class="navys-link" :to="{name: 'accueil'}" :class="{ 'active': this.$route.name === 'accueil' }" data-bs-toggle="tooltip" data-bs-placement="right" title="Accueil">
          <img src="@/assets/icons/Home/home.svg" alt="icone">
        </router-link>
        <router-link v-if="getPermission('voir-camions') === true || getPermission('voir-interventions') === true || getPermission('voir-proprietaires') === true" class="navys-link" :to="{name: 'camions'}" :class="{ 'active': (this.$route.name === 'camions') || (this.$route.name === 'interventions') || (this.$route.name === 'proprietaires') }" data-bs-toggle="tooltip" data-bs-placement="right" title="Parc Automobile">
          <img src="@/assets/parc.png" alt="icone">
        </router-link>
        <router-link v-else-if="this.$store.getters.transporteur" class="navys-link" :to="{name: 'camions'}" :class="{ 'active': (this.$route.name === 'camions') || (this.$route.name === 'interventions') || (this.$route.name === 'proprietaires') }" data-bs-toggle="tooltip" data-bs-placement="right" title="Parc Automobile">
          <img src="@/assets/parc.png" alt="icone">
        </router-link>
        <router-link v-if="getPermission('voir-employes') === true " class="navys-link" :to="{name: 'employes'}" :class="{ 'active': (this.$route.name === 'employes') ||(this.$route.name === 'employes/chauffeurs') ||(this.$route.name === 'employes/employe') ||(this.$route.name === 'contrats')||(this.$route.name === 'conges') || (this.$route.name === 'affectations')}" data-bs-toggle="tooltip" data-bs-placement="right" title="Ressources Humaines">
          <img src="@/assets/rh.png" alt="icone">
        </router-link>
        <router-link v-if="getPermission('voir-courses') === true " class="navys-link" :to="{name: 'courses'}" :class="{ 'active': (this.$route.name === 'courses')||(this.$route.name === 'clients')||(this.$route.name === 'annonces')||(this.$route.name === 'colis')||(this.$route.name === 'programmes') || (this.$route.name === 'courses.demandes') || (this.$route.name === 'courses.demenagements') || (this.$route.name === 'paiements')|| (this.$route.name === 'chats')}" data-bs-toggle="tooltip" data-bs-placement="right" title="Operations">
          <img src="@/assets/icons/Operations.svg" alt="icone">
        </router-link>
        <router-link v-else-if="this.$store.getters.transporteur" class="navys-link" :to="{name: 'courses'}" :class="{ 'active': (this.$route.name === 'courses')||(this.$route.name === 'clients')||(this.$route.name === 'annonces')||(this.$route.name === 'colis')||(this.$route.name === 'programmes') || (this.$route.name === 'courses.demandes') || (this.$route.name === 'courses.demenagements') || (this.$route.name === 'paiements')|| (this.$route.name === 'chats')}" data-bs-toggle="tooltip" data-bs-placement="right" title="Operations">
          <img src="@/assets/icons/Operations.svg" alt="icone">
        </router-link>
        <router-link v-if="getPermission('voir-factures') === true || getPermission('voir-paiement') === true" class="navys-link" :to="{name: 'factures'}" :class="{ 'active': (this.$route.name === 'factures')||(this.$route.name === 'pieces_de_caisse')||(this.$route.name === 'journaux')||(this.$route.name === 'achats') }" data-bs-toggle="tooltip" data-bs-placement="right" title="Comptabilité">
          <img src="@/assets/icons/Compta.svg" alt="icone">
        </router-link>
        <router-link v-if="this.$store.getters.is_admin == true" class="navys-link" :to="{name: 'carte'}" :class="{ 'active': this.$route.name === 'carte' }" data-bs-toggle="tooltip" data-bs-placement="right" title="Geolocalisation">
          <img src="@/assets/icons/Geolocalisation.svg" alt="icone">
        </router-link>
        <router-link v-if="this.$store.getters.is_admin == true" class="navys-link" :to="{name: 'tableau-de-bord'}" :class="{ 'active': this.$route.name === 'tableau-de-bord' }" data-bs-toggle="tooltip" data-bs-placement="right" title="Tableau de bord">
          <img src="@/assets/tab.png" alt="icone">
        </router-link>
        <router-link v-if="getPermission('voir-roles') === true" class="navys-link" :to="{name: 'configurations'}" :class="{ 'active': this.$route.name === 'configurations' }" data-bs-toggle="tooltip" data-bs-placement="right" title="Configurations">
          <img src="@/assets/icons/Parametres.svg" alt="icone">
        </router-link>
      </nav>
    </div>
  </div>
</template>


<script>

export default ({
    methods: {
      getPermission(permission) {
        return this.$store.getters.permissions.includes(permission)
      }
    }  
})
</script>
    