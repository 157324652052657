<template>
  <div class="pt-5">
    <div class="col-md-4 mx-auto">
      <div class="">
        <div class="text-center my-3">
          <img src="@/assets/logo1.png" height="100" alt="Logo" loading="lazy" />
        </div>

        <div class="card login-box">
          <div class="card-body py-5">
            <h2 class="text-center mb-4 text-primary">CONNEXION</h2>

            <form @submit.prevent="login()">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa-solid fa-user"></i>
                  </span>
                </div>
                <input placeholder="Email" type="email" class="form-control form-control-lg "
                  :class="{ 'is-invalid': status === false }" v-model="form.email" required autocomplete="email" autofocus>
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa-solid fa-lock"></i>
                  </span>
                </div>
                <input placeholder="Mot de passe" type="password" class="form-control form-control-lg"
                  :class="{ 'is-invalid': status === false }" v-model="form.password" required
                  autocomplete="current-password">
              </div>

              <div class=" mb-0 text-center">
                <button type="submit" class="btn btn-lg btn-block btn-primary" :disabled="isLoading">
                  <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  Connexion
                </button>

                <a class="btn btn-link " :href="this.$store.getters.apiUrl + '/password/reset'">
                  Mot de passe oublié ?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'LoginView',
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login() {
      this.isLoading = true
      this.axios.post('/api/login', this.form).then(response => {
        if (response.data.success === true) {
          const user = response.data.data
          this.axios.defaults.headers.common = {
            'Authorization': 'Bearer ' + user.token
          };
          this.$store.commit('setAuthenticated', user)
          this.getTransporteurAccount()
          this.$router.push({ name: 'accueil' })
          this.resetForm()
        }
        else {
          this.$toast.add({
            severity: 'error',
            summary: 'Identifiants',
            detail: response.data.errors[0],
            life: 5000
          });
        }
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    resetForm() {
      this.form = {
        email: '',
        password: ''
      }
    },
    getTransporteurAccount(){
      this.axios.get('/api/proprietaires/user/'+this.$store.getters.user.id).then((response) => {
        const comptes = response.data
        if (comptes) {
          this.$store.commit('setTransporteur', comptes)
        }
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      });
    },
  }
}
</script>

<style>
.login-box {
  min-width: 300px;
  max-width: 350px;
  margin: auto;
}
</style>