<template>
    <div v-if="this.$store.getters.transporteur" class="btn-group page-nav " role="group" >
        <router-link class="btn" :to="{ name: 'courses' }" :class="{ 'active': this.$route.name === 'courses' }" >
            <i class="fa-solid fa-truck-moving"></i> Courses 
        </router-link>
    </div>
    <div v-else class="btn-group page-nav " role="group" >
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false"  :class="{ 'active': this.$route.name === 'courses' || this.$route.name === 'courses.demandes' || this.$route.name === 'courses.demenagements' }" v-if="getPermission('voir-courses') === true">
            <i class="fa-solid fa-truck-moving"></i> Courses 
        </button>
        <div class="dropdown-menu">
            <router-link class="dropdown-item" :class="{ 'active': this.$route.name === 'courses' }" :to="{name: 'courses'}">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-2">
                        Programmes
                    </div>
                    <div class="badge badge-danger px-2">
                        {{ CoursesNonValides }}
                    </div>
                </div>
            </router-link>
            <router-link class="dropdown-item" :class="{ 'active': this.$route.name === 'courses.demandes' }" :to="{name: 'courses.demandes'}">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-2">
                        Demandes
                    </div>
                    <div class="badge badge-danger px-2">
                        {{ compteDemande }}
                    </div>
                </div>
            </router-link>
            <!-- <router-link class="dropdown-item" :class="{ 'active': this.$route.name === 'courses.demenagements' }" :to="{name: 'courses.demenagements'}">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-2">
                        Déménagements
                    </div>
                    <div class="badge badge-danger px-2">
                        {{ compteDemenagement }}
                    </div>
                </div>
            </router-link> -->
        </div>

        <router-link class="btn" :to="{ name: 'paiements' }" :class="{ 'active': this.$route.name === 'paiements' }" v-if="getPermission('voir-paiements') === true">
            <i class="fa-solid fa-money-bill-transfer"></i> Paiements
        </router-link>

        <router-link class="btn" :to="{ name: 'annonces' }" :class="{ 'active': this.$route.name === 'annonces' }" v-if="getPermission('voir-annonces') === true">
            <i class="fa-solid fa-bullhorn"></i> Annonces
        </router-link>

        <router-link class="btn" :to="{ name: 'clients' }" :class="{ 'active': this.$route.name === 'clients' }" v-if="getPermission('voir-clients') === true">
            <i class="fa-solid fa-user-group"></i> Clients
        </router-link>

        <router-link class="btn" :to="{ name: 'chats' }" :class="{ 'active': this.$route.name === 'chats' }"  v-if="getPermission('voir-chats') === true">
            <i class="fa-solid fa-envelope"></i> Conversations
        </router-link>

        <router-link class="btn" :to="{ name: 'programmes' }" :class="{ 'active': this.$route.name === 'programmes' }" v-if="getPermission('voir-courses') === true">
            <i class="fa-solid fa-calendar"></i> Calendrier
        </router-link>
    </div>
</template>

<script>

export default {
    data() {
        return{
            compteDemande: 0,
            compteDemenagement: 0,
            CoursesNonValides: 0
        }
    },
    mounted(){
        this.getCourseDemande()
        this.getCourseNonValides()
        this.getDemenagements()
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        getCourseDemande(){
            this.isLoading = true
            this.axios.get('/api/course_demandes?statut=En attente&req_count=1').then((response) => {
                this.compteDemande = response.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getCourseNonValides(){
            this.isLoading = true
            this.axios.get('/api/courses?est_valide=0&req_count=1').then((response) => {
                this.CoursesNonValides = response.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getDemenagements(){
            this.isLoading = true
            this.axios.get('/api/demenagements?statut=En attente&req_count=1').then((response) => {
                this.compteDemenagement = response.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        }
    },
};
</script>